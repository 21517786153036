<template>
  <div v-if="event">
    <Button class="text-brand-color text-sm" @click="open = true">Event Details</Button>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-40" @close="open = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                         leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </TransitionChild>

        <div class="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300"
                             enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                             enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                             leave-from="opacity-100 translate-y-0 sm:scale-100"
                             leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel
                  class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div class="mx-auto flex  items-center justify-center rounded-full bg-green-100">
                    <img :src="event.ClubImage" class="max-h-full rounded"/>
                  </div>
                  <div class="mt-3 sm:mt-5">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ event.ClubName }}
                    </DialogTitle>
                    <div class="mt-2 mb-4">
                      <p class="text-sm text-gray-500">{{ event.Description }}</p>
                    </div>

<!--                    <div class="flex flex-row flex-grow " v-if="getDateTime()">
                      <div>
                        <Icon name="uim:calender" class="text-brand-color w-5 h-5 px-1"/>
                      </div>
                      <div>
                        <span class="text-sm text-gray-500">{{ getDateTime() }}</span>
                      </div>
                    </div>-->

<!--                    <div class="flex flex-row flex-grow" v-if="getLocation()">
                      <div>
                        <Icon name="material-symbols:location-on-rounded" class="text-brand-color w-5 h-5 px-1"/>
                      </div>
                      <div>
                        <span class="text-sm text-gray-500">{{ getLocation() }}</span>
                      </div>
                    </div>-->

                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <button type="button"
                          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          @click="open = false" ref="cancelButtonRef">Close
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import {ref} from 'vue'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {CheckIcon} from '@heroicons/vue/24/outline'
import {getDateFromDBDate1} from "~/utils/helpers";

const open = ref(false)


let {event, contest} = defineProps(['event', 'contest'])

onMounted(() => {

  console.log(event)
})


function getDateTime() {

  if (event) {

    let endDate = event.ScheduleSettings.endDate;
    let startDate = event.ScheduleSettings.startDate;
    let type = event.ScheduleSettings.type;
    let weekConfig = event.ScheduleSettings.weekConfig;

    if (startDate === endDate) {
      return getDateFromDBDate1(startDate)
    } else {
      return `${getDateFromDBDate1(startDate)} - ${getDateFromDBDate1(endDate)}`;
    }

  }
}


function getLocation() {

  if (event && event.Location) {
   return `${event.Location.Name}\n${event.Location.Address}`

  }
}
</script>
