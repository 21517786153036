<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6 z-50">
        <div class="flex w-full flex-col items-center space-y-4">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <div v-if="show" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="px-4 py-2 cursor-pointer">
                        <div class="flex flex-row justify-between items-center">
                            <div class="flex flex-col gap-1">
                                <p class="mt-1 text-sm text-gray-500">Photo uploading</p>
                                <p class="mt-1 text-xs text-gray-400">{{Math.min(uploaded, total)}}/{{total}} Completed</p>
                            </div>
                            <Icon class="animate-bounce text-brand-color w-5 h-5" name="material-symbols:arrow-circle-up-outline"/>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>



<script setup lang="ts">
import {ref} from 'vue'
import {CheckCircleIcon, BugAntIcon, ExclamationTriangleIcon} from '@heroicons/vue/24/outline'
import {XMarkIcon} from '@heroicons/vue/20/solid'
import {
    useGlobalEventLister,
    usePostUploadingToast
} from "~/composables/useEventBus";


const show = ref<boolean>(false)
const total = ref<number>(0)
const uploaded = ref<number>(0)

function setupEventListener(){
    useGlobalEventLister(usePostUploadingToast(), function (data:any){
        show.value = data.show;
        total.value = data.total;
        uploaded.value = data.uploaded;
    })
}

onMounted(()=>{
    setupEventListener();
})
</script>